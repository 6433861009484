<template>
  <div
    v-loading="isLoading"
    element-loading-text="Загрузка..."
  >
    <el-form>
      <el-form-item>
        <el-upload
          :headers="getHeaders()"
          ref="upload"
          :action="`${$config.api}/netcorespatialservice/convert/`"
          :auto-upload="false"
          :on-success="onSuccess"
          :on-error="onError"
          :before-upload="beforeUpload"
          :limit="1"
          :data="sendData"
        >
          <el-button slot="trigger" size="small" type="primary">Выбрать файл</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="Система координат файла">
        <coordinate-system
          class="coordinateSystem"
          @change-cs="sendData.epsg = $event"
          v-model="sendData.epsg"
          :options="userCSs"
          :key="`coordinate_system_${coordinateSystemComponentKey}`"
        ></coordinate-system>
        <p v-if="!sendData.epsg" class="error">заполните поле</p>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="sendData.need_to_convert_closed_line_to_polygon" label="Преобразовать замкнутые контура в полигоны"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="sendData.swap_x_y_axes" label="Поменять оси местами после пересчёта координат"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="sendData.swap_axes_before_transform" label="Поменять оси местами до пересчёта координат"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="sendData.is_flattening_needed" label="Убрать z-координаты"></el-checkbox>
      </el-form-item>
      <el-collapse v-if="result.filename">
        Результат импорта файла"{{ result.filename }}"
        <el-collapse-item
          :title="result.message"
          v-if="result.description && result.description.length > 0">
          <div v-for="d in result.description">
          {{ d }}
            <br>
          </div>
        </el-collapse-item>
        <div v-else><br>{{result.message}}</div>
      </el-collapse>
    </el-form>
    <el-button @click="upload">{{ sendImportButtonText }}</el-button>
  </div>
</template>

<script>
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

export default {
  name: 'fileDXFForm',
  components: { CoordinateSystem },
  props: {
    userCSs: {
      type: Array
    },
    importLayerGeometryField: {
      type: String,
      default: null
    },
    defaultImportSettings: {
      type: Object,
      default: false
    }
  },
  data () {
    return {
      sendData: {
        epsg: this.defaultImportSettings.epsg,
        need_to_convert_closed_line_to_polygon: this.defaultImportSettings.need_to_convert_closed_line_to_polygon,
        swap_x_y_axes: this.defaultImportSettings.swap_x_y_axes,
        swap_axes_before_transform: this.defaultImportSettings.swap_axes_before_transform,
        is_flattening_needed: this.defaultImportSettings.is_flattening_needed,
        geom_attr_id: this.importLayerGeometryField,
        load_invalid_geometry: true
      },
      originalFileName: null,
      loading: true,
      response: null,
      result: {
        filename: null,
        message: null,
        description: null
      },
      sendImportButtonTextByDefault: 'Импортировать',
      sendImportButtonText: this.sendImportButtonTextByDefault || 'Импортировать',
      isLoading: false,
      coordinateSystemComponentKey: 0
    }
  },
  methods: {
    validate () {
      return this.sendData.epsg != null
    },
    upload () {
      if (!this.validate()) {
        return
      }
      this.$refs.upload.submit()
    },
    beforeUpload (file) {
      this.isLoading = true
      this.originalFileName = file.name
    },
    changeCS (newCS) {
      this.sendData.fileCS = newCS
    },
    onSuccess (answer) {
      this.isLoading = false
      if (!answer.hasOwnProperty('features')) {
        // ошибка в обработке файла (бэк)
        this.$set(this, 'result', {
          filename: this.originalFileName,
          message: answer.message,
          description: answer.parameters
        })
        this.$alert(answer, 'Ошибка', { confirmButtonText: 'OK' })
      } else {
        // все хорошо
        this.$set(this, 'result', {
          filename: this.originalFileName,
          message: `Было добавлено ${answer.features.length} фич`,
          description: []
        })
        this.$emit('features-imported', answer, this.epsg)
      }
    },
    onError (answer) {
      this.isLoading = false
      this.$set(this, 'result', {
        filename: this.originalFileName,
        message: answer.message,
        description: [answer]
      })
      this.$alert(answer, 'Ошибка', { confirmButtonText: 'OK' })
    },
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    }
  },
  watch: {
    defaultImportSettings: {
      handler: async function () {
        this.sendData.epsg = this.defaultImportSettings.epsg
        this.sendData.need_to_convert_closed_line_to_polygon = this.defaultImportSettings.need_to_convert_closed_line_to_polygon
        this.sendData.swap_x_y_axes = this.defaultImportSettings.swap_x_y_axes
        this.sendData.swap_axes_before_transform = this.defaultImportSettings.swap_axes_before_transform
        this.sendData.is_flattening_needed = this.defaultImportSettings.is_flattening_needed
        this.coordinateSystemComponentKey += 1;
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.error {
    color: red;
    font-size: 11px;
    margin-top: 0;
  }
</style>
