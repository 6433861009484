import { render, staticRenderFns } from "./fileDXFForm.vue?vue&type=template&id=2b31f528&scoped=true&"
import script from "./fileDXFForm.vue?vue&type=script&lang=js&"
export * from "./fileDXFForm.vue?vue&type=script&lang=js&"
import style0 from "./fileDXFForm.vue?vue&type=style&index=0&id=2b31f528&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b31f528",
  null
  
)

export default component.exports